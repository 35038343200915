import { getMachinesNames } from '@/api/machine';
import { ONE_HOUR_MS } from '@/lib/constants';
import { useQuery } from '@tanstack/react-query';

export function useMachinesNames() {
  return useQuery({
    queryKey: ['machinesNames'],
    queryFn: getMachinesNames,
    staleTime: ONE_HOUR_MS,
  });
}
