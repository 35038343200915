import { getNotification } from '@/api/notification';
import { useQuery } from '@tanstack/react-query';
import { useSocket } from './useSocket';
import { useEffect } from 'react';
import {
  NEW_DEFAULT_NOTIFICATION,
  NEW_ELECTICITY_NOTIFICATION,
  NEW_PRESSURE_NOTIFICATION,
  NOTIFICATION_ROOM,
} from '@/lib/constants';

type NotificationProps = {
  limit?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  machineIds?: string;
};

export function useNotification(props: NotificationProps, callback?: () => void) {
  const socket = useSocket();
  const queryKey = ['notification', props.limit, props.page, props.startDate, props.endDate, props.machineIds].filter(
    Boolean,
  );
  const query = useQuery({
    queryKey,
    queryFn: () => getNotification(props.limit, props.page, props.startDate, props.endDate, props.machineIds),
  });

  useEffect(() => {
    socket.emit('join_notification_room', NOTIFICATION_ROOM);

    socket.on(NEW_DEFAULT_NOTIFICATION, () => {
      query.refetch();
      callback?.();
    });
    socket.on(NEW_ELECTICITY_NOTIFICATION, () => {
      query.refetch();
      callback?.();
    });
    socket.on(NEW_PRESSURE_NOTIFICATION, () => {
      query.refetch();
      callback?.();
    });

    return () => {
      socket.off(NEW_DEFAULT_NOTIFICATION);
      socket.off(NEW_ELECTICITY_NOTIFICATION);
      socket.off(NEW_PRESSURE_NOTIFICATION);
    };
  });

  return query;
}
