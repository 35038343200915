import React, { createContext, useEffect, useState } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase/firebase';
import LoadingState from '@/components/LoadingState';
import { logout } from '@/firebase/auth';
import { TOKEN_EXPIRATION_TIME } from '@/lib/config';
import axios from '@/lib/axios';
import { useQueryClient } from '@tanstack/react-query';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  isAuthenticated: boolean;
  userRole: string;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  userRole: '',
  loading: true,
  isAuthenticated: false,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const qc = useQueryClient();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const currentUser = auth.currentUser;
      if (user && currentUser) {
        const { token, expirationTime, claims } = await currentUser.getIdTokenResult();

        setRole(claims.role as string);
        // Check if the stored expiry time is less than the current time
        const storedTokenExpiryTime =
          Number(localStorage.getItem(TOKEN_EXPIRATION_TIME)) || new Date(expirationTime).getTime();
        const currentTime = new Date().getTime();

        if (storedTokenExpiryTime && +storedTokenExpiryTime < currentTime) {
          await logout();
          return;
        }

        // set the bearer token in the axios instance
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      } else {
        // remove queries when user is logged out
        qc.removeQueries();
      }

      setUser(user);
      setLoading(false);
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, [qc]);

  if (loading)
    return (
      <div className="h-screen w-screen grid place-items-center">
        <LoadingState />
      </div>
    );

  return (
    <AuthContext.Provider value={{ user, loading, isAuthenticated, userRole: role }}>{children}</AuthContext.Provider>
  );
};
