import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Converts a path string into a breadcrumb string by splitting the path by dashes,
 * capitalizing each part, and joining them back together with spaces.
 *
 * @param {string} path - The path string to convert.
 * @returns {string} The breadcrumb string.
 */
export const getBreadcrumbFromPath = (path: string) => path.split('-').map(capitalize).join(' ');

export function generatePassword(length = 12) {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.?';

  const allChars = uppercase + lowercase + numbers + specialChars;

  // Ensure the password contains at least one of each required character type
  let password =
    getRandomChar(uppercase) + getRandomChar(lowercase) + getRandomChar(numbers) + getRandomChar(specialChars);

  // Fill the rest of the password length with random characters
  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the characters to avoid predictable patterns
  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
}

function getRandomChar(str: string) {
  return str[Math.floor(Math.random() * str.length)];
}
