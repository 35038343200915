import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="grid place-items-center h-full">
      <div className="flex flex-col items-center gap-4">
        <img src="/svg/not-found.svg" />
        <div className="space-y-4 relative -top-20 text-center">
          <p className="text-slate-700 text-3xl relative">La page que vous recherchez n'existe pas</p>
          <Button onClick={() => navigate(-1)}>Retourner à la page précedente</Button>
        </div>
      </div>
    </div>
  );
}
