import axios from '@/lib/axios';
import { Notification, NotificationEmail, PaginationMetadata, PressureConfig } from '@/types/notification';

type NotificationType = { notifications: Notification[]; paginationMetadata: PaginationMetadata };

export const getNotification = async (
  limit?: number,
  page?: number,
  startDate?: string,
  endDate?: string,
  machineIds?: string,
): Promise<NotificationType> => {
  const queryParams = new URLSearchParams();
  if (limit) queryParams.append('limit', limit.toString());
  if (page) queryParams.append('page', page.toString());
  if (startDate) queryParams.append('startDate', startDate);
  if (endDate) queryParams.append('endDate', endDate);
  if (machineIds) queryParams.append('machine_id', machineIds);

  const res = await axios.get(`/notification/notifications?${queryParams.toString()}`);
  const notifications: Notification[] = res.data.notifications;
  const paginationMetadata: PaginationMetadata = res.data.pagination;
  return { notifications, paginationMetadata };
};

export const allNotificationEmails = async (): Promise<NotificationEmail[]> => {
  return (await axios.get('/notification/allEmails')).data.emails;
};

export const updateNotifiedEmails = async (emails: string[], id: string) => {
  return await axios.post(`/notification/updateEmails/${id}`, { emails });
};

export const getPressureConfig = async (): Promise<PressureConfig[]> => {
  return (await axios.get('/notification/notifConfig')).data.config;
};

export const updatePressureConfig = async (config: PressureConfig) => {
  return await axios.post(`/notification/updateNotificationConfig/${config._id}`, { ...config });
};
