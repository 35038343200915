import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { useNotification } from '@/hooks/useNotification';
import { usePressureConfig } from '@/hooks/usePressureConfig';
import { convertToLocalTime } from '@/lib/date';
import { NotificationType, type Notification } from '@/types/notification';
import { Loader, LucideAlertCircle } from 'lucide-react';
import { forwardRef, useEffect, useState } from 'react';
import { FaGear } from 'react-icons/fa6';
import { HiOutlineBoltSlash } from 'react-icons/hi2';
import { MdOutlineNotificationsActive, MdSensorsOff } from 'react-icons/md';
import { Link } from 'react-router-dom';

const OPEN_NOTIFICATIONS_COMMAND = 'm';

type AllNotifications = {
  key: number; // to distanguish between the pages
  notifications: Notification[];
};

export function Notification() {
  const [open, setOpen] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const [allNotifications, setAllNotifications] = useState<AllNotifications[]>([]);

  const [page, setPage] = useState(1);

  const newNotificationCallback = () => setNewNotification(true);

  const { data, isLoading } = useNotification(
    {
      limit: 10,
      page,
    },
    newNotificationCallback,
  );

  const notificationsData = data?.notifications;

  const { query } = usePressureConfig();
  const config = query.data?.[0];
  const maxPressure = config?.maxPressure || 4;

  useEffect(() => {
    if (notificationsData)
      setAllNotifications((prev) => {
        const check = prev.find((p) => p.key === page);
        if (!check) {
          return [...prev, { key: page, notifications: notificationsData }];
        }
        return prev;
      });
  }, [notificationsData, page]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === OPEN_NOTIFICATIONS_COMMAND && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        setOpen((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const notifications = allNotifications.map((n) => n.notifications).flat();

  return (
    <Sheet
      open={open}
      onOpenChange={(v) => {
        setOpen(v);
        setNewNotification(false);
      }}
    >
      <SheetTrigger asChild className="relative">
        <Button
          variant="outline"
          size="icon"
          className="focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0 rounded-full"
        >
          {newNotification && <span className="absolute size-2 rounded-full bg-red-600 right-0 top-1 z-50"></span>}
          <MdOutlineNotificationsActive />
        </Button>
      </SheetTrigger>
      <SheetContent className="p-0 outline-none overflow-y-auto" hideCloseButton>
        <SheetHeader className="mb-2 px-4 py-3 flex-row items-center justify-between space-y-0">
          <SheetTitle>Notifications</SheetTitle>
          <Link
            to="/settings/notifications"
            className="text-slate-600 hover:text-slate-700"
            onClick={() => setOpen(false)}
          >
            <FaGear />
          </Link>

          <SheetDescription className="hidden">Notifications</SheetDescription>
        </SheetHeader>
        <div className="flex flex-col divide-y">
          {notifications && notifications.length > 0 ? (
            <>
              {notifications.map((notif) => {
                let title: React.ReactElement | string = '';

                if (notif.type == 'machine_pressure') {
                  title = (
                    <p>
                      la pression de l'eau{' '}
                      <span className="font-medium">
                        {notif.device_name.includes('chlorée') ? 'chlorée' : 'de rinçage'}
                      </span>{' '}
                      de <span className="font-medium">{notif.machine_id?.name}</span> atteint son seuil{' '}
                      {notif.device_pressure > maxPressure ? 'maximale' : 'minimale'}{' '}
                      <span className="font-medium">{Math.abs(notif.device_pressure).toFixed(1)}</span>
                    </p>
                  );
                }
                if (notif.type == 'zone_status') {
                  title = `la zone ${notif.zone_id?.name} eu une coupure électrique`;
                }
                if (notif.type == 'machine_status') {
                  title = `la machine ${notif.machine_id?.name} est en êtat de defaut`;
                }
                return <NotificationItem key={notif._id} date={notif.created_at} title={title} status={notif.type} />;
              })}
              <div className="p-4 w-full flex justify-center">
                {isLoading ? (
                  <Loader className="animate-spin text-slate-600 size-5" />
                ) : (
                  <Button className="w-full" onClick={() => setPage(page + 1)}>
                    Charger plus
                  </Button>
                )}
              </div>
            </>
          ) : (
            <p className="text-xs text-muted-foreground px-4">Aucune notification</p>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}
const NotificationItem = forwardRef<
  HTMLDivElement,
  { status: NotificationType; date: string; title: string | React.ReactElement }
>(({ status, date, title }, ref) => {
  const NotifIcon =
    status === 'zone_status' ? HiOutlineBoltSlash : status === 'machine_pressure' ? LucideAlertCircle : MdSensorsOff;
  return (
    <div ref={ref} className="grid grid-cols-[auto_1fr] py-4 gap-3 hover:bg-gray-100 rounded-md cursor-default px-4">
      <div className="p-2 rounded-md bg-gray-100 grid place-items-center">
        <NotifIcon className="text-red-500 size-6" />
      </div>
      <div>
        <div className="flex flex-col text-sm gap-1 justify-start items-start">
          <div className="text-red-700">{title}</div>
          <span className="text-xs text-muted-foreground">{convertToLocalTime(date)}</span>
        </div>
      </div>
    </div>
  );
});
