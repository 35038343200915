import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import SidebarImage from '@/components/app/SidebarImage';

import { Link, useLocation } from 'react-router-dom';

import { MdSettings, MdSpaceDashboard } from 'react-icons/md';
import { HiMiniSquares2X2, HiMiniUsers } from 'react-icons/hi2';
import { useAuth } from '@/hooks/useAuth';
// import { FaBell } from 'react-icons/fa';

// Menu items.
const items = [
  {
    title: 'Tableau de bord',
    url: '/dashboard',
    icon: MdSpaceDashboard,
  },
  {
    title: 'Gestion des machines',
    url: '/machines',
    icon: HiMiniSquares2X2,
  },
  // {
  //   title: 'Gestion des capteurs',
  //   url: '/capteurs',
  //   icon: MdOutlineSensors,
  // },
  {
    title: 'Gestion des utilisateurs',
    url: '/utilisateur',
    icon: HiMiniUsers,
    admin: true,
  },
  // {
  //   title: 'Notifications',
  //   url: '/notifications',
  //   icon: FaBell,
  // },
  {
    title: 'Paramètres',
    url: '/settings',
    icon: MdSettings,
  },
];

export default function AppSidebar() {
  const { pathname } = useLocation();
  const currentItem = pathname.split('/')[1];

  const { userRole } = useAuth();

  return (
    <Sidebar collapsible="icon" className="bg-white">
      <SidebarHeader className="items-center">
        <SidebarImage />
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) =>
                item.admin && userRole !== 'admin' ? null : (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton tooltip={item.title} asChild isActive={currentItem === item.url.split('/')[1]}>
                      <Link to={item.url}>
                        <item.icon />
                        <span>{item.title}</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ),
              )}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
