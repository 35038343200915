import { SOCKET_URL } from '@/lib/config';
import { createContext } from 'react';
import { io, Socket } from 'socket.io-client';

// Create context
export const SocketContext = createContext<Socket | null>(null);

// Initialize socket
const socket = io(SOCKET_URL);

socket.on('connect', () => {
  console.log('connected to socket');
});

socket.on('disconnect', () => {
  console.log('disconnected from socket');
});

// Provide socket to children
export default function SocketContextProvider({ children }: { children: React.ReactNode }) {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}
