import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  User,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth } from './firebase';
import { TOKEN_EXPIRATION_TIME } from '@/lib/config';

export const register = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const login = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const resetPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

export const logout = async () => {
  // remove the token expiration time from local storage
  localStorage.removeItem(TOKEN_EXPIRATION_TIME);
  return await signOut(auth);
};

export async function changeUserPassword(user: User, currentPassword: string, newPassword: string) {
  if (!user) {
    throw new Error('No user is signed in');
  }

  if (!user.email) {
    throw new Error('User email is not available');
  }

  // Step 1: Re-authenticate the user if needed
  const credential = EmailAuthProvider.credential(user.email, currentPassword);

  await reauthenticateWithCredential(user, credential);

  // Step 2: Update the password
  await updatePassword(user, newPassword);
}
