import { Link, useLocation } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Fragment } from 'react';
import { IoMdHome } from 'react-icons/io';
import { getBreadcrumbFromPath } from '@/lib/utils';
import { useMachinesNames } from '@/hooks/useMachinesNames';

export default function AppBreadcrumb() {
  const { pathname } = useLocation();
  const { data: machinesNames } = useMachinesNames();
  const breadcrumbs = pathname
    .split('/')
    .slice(1)
    .map((b) => {
      const breadcrumb = {
        name: b,
        link: b,
      };
      if (b.length === 24 && !b.includes(' ')) {
        const machine = machinesNames?.find((m) => m._id === b);
        const machineBreadCrumb = {
          name: machine?.name,
          link: 'machines/' + machine?._id,
        };
        return machine ? machineBreadCrumb : breadcrumb;
      }
      return breadcrumb;
    });
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <Link to="/dashboard">
            <BreadcrumbLink asChild>
              <IoMdHome />
            </BreadcrumbLink>
          </Link>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        {breadcrumbs.slice(0, -1).map((breadcrumb) => (
          <Fragment key={breadcrumb.name}>
            <BreadcrumbItem className="text-xs font-medium">
              <Link to={'/' + breadcrumb.link}>{getBreadcrumbFromPath(breadcrumb.name ?? '')}</Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </Fragment>
        ))}

        <BreadcrumbItem>
          <BreadcrumbPage className="text-azura-blue-dark text-xs font-semibold">
            {getBreadcrumbFromPath(breadcrumbs.at(-1)?.name ?? '')}
          </BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
}
