import { getPressureConfig, updatePressureConfig } from '@/api/notification';
import { ONE_HOUR_MS } from '@/lib/constants';
import { PressureConfig } from '@/types/notification';
import { useMutation, useQuery } from '@tanstack/react-query';

export function usePressureConfig() {
  const query = useQuery({
    queryKey: ['pressure-config'],
    queryFn: getPressureConfig,
    staleTime: ONE_HOUR_MS,
  });

  const mutation = useMutation({
    mutationFn: (config: PressureConfig) => updatePressureConfig(config),
    onSuccess: () => {
      query.refetch();
    },
  });

  return { query, mutation };
}
