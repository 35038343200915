import NotFound from '@/components/app/NotFound';
import { useAuth } from '@/hooks/useAuth';
import { Outlet } from 'react-router-dom';

function RolesMiddlware({
  roles,
  asParentRoute = false,
  children,
}: {
  roles: string[];
  asParentRoute?: boolean;
  children?: React.ReactElement;
}) {
  const { userRole } = useAuth();

  if (!roles.includes(userRole)) {
    return <NotFound />;
  }

  return asParentRoute ? <Outlet /> : children;
}

export default RolesMiddlware;
