export const CRITICALITY_LEVELS = {
  RELIABLE: 'reliable',
  MODERATE: 'moderate',
  HIGH: 'high',
  CRITICAL: 'critical',
};

export type CriticalityLevel = keyof typeof CRITICALITY_LEVELS;

export const MACHINE_STATUSES = {
  ON: 'ON',
  OFF: 'OFF',
  DEF: 'DEF',
};

export type MachineStatus = keyof typeof MACHINE_STATUSES;

export const DASHBOARD_ROOM = 'marissa_dashboard_room';
export const NEW_DEF_MACHINE_STATUS = 'marissa_new_def_machine';
export const NEW_NOTIFICATION_EVENT = 'marissa_new_notif';
export const DEVICES_ROOM = 'marissa_devices_room';
export const DEVICE_UPDATE_EVENT = 'marissa_new_status';
export const NEW_PRESSURE_EVENT = 'marissa_new_pressure';

export const NOTIFICATION_ROOM = 'maraissa_notification_room'
export const NEW_PRESSURE_NOTIFICATION = 'maraissa_pressure_notif';
export const NEW_DEFAULT_NOTIFICATION = 'maraissa_def_notif';
export const NEW_ELECTICITY_NOTIFICATION = 'maraissa_electric_notif';


export const ONE_HOUR_MS = 1000 * 60 * 60;
export const ONE_MINUTE_MS = 1000 * 60;
