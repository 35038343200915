import axios from '@/lib/axios';
import { Machine, MachineName, SingleMachine, SingleMachinePressures } from '@/types/machine';

// get all machines
export const getAllMachines = async (zone_id: string) => {
  let query = '';
  if (zone_id) query += '?zone_id=' + zone_id;

  const req = await axios.get(`/machine/allMachines${query}`);
  return req.data.results as Machine[];
};

// get machines names
export const getMachinesNames = async (): Promise<readonly MachineName[]> => {
  const req = await axios.get('/machine/machinesNames');
  return req.data.machines;
};

// get single machine data
export const getSingleMachine = async (machineId: string): Promise<SingleMachine> => {
  const req = await axios.get(`/machine/getMachine/${machineId}`);
  return { machine: req.data.result, statuses: req.data.machineStatuses };
};

// get single machine pressures data
export const getSingleMachinePressures = async (
  machineId: string,
  startDate?: string,
  endDate?: string,
): Promise<SingleMachinePressures> => {
  let query = '';
  if (startDate && endDate) query = `?startDate=${startDate}&endDate=${endDate}`;
  return (await axios.get(`/machine/machinePressureData/${machineId}${query}`)).data;
};

// get machine work time
export const getMachineWorkTime = async (machineId: string): Promise<string> => {
  const req = await axios.get(`/machine/getMachineWorkTime/${machineId}`);
  return req.data.workTime;
};

// CREATE MACHINE
export const createMachine = async (machine: FormData) => {
  await axios.post('/machine/newMachine', machine, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
