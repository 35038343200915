import LoadingState from '@/components/LoadingState';
import React, { ComponentType, Suspense } from 'react';

const loadable = <P extends object>(
  importFunc: () => Promise<{ default: ComponentType<P> }>,
  height: string = 'h-full',
): React.FC<P> => {
  const LazyComponent = React.lazy(importFunc);

  return (props: P) => (
    <Suspense
      fallback={
        <div className={`${height} grid w-full place-items-center`}>
          <LoadingState />
        </div>
      }
    >
      <LazyComponent {...(props as any)} />
    </Suspense>
  );
};

export default loadable;
