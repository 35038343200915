import { useSidebar } from '../ui/sidebar';

const azuraFaviconUrl = 'https://www.azura-group.com/templates/azura/favicon.ico';

export default function SidebarImage() {
  const { open } = useSidebar();
  const className = (open ? 'w-32 h-14 p-1' : 'size-7 p-1') + ' hidden md:inline';
  return (
    <>
      <img src="/images/logo.png" alt="Azura logo" className="md:hidden w-20 h-10" />
      <img src={open ? '/images/logo.png' : azuraFaviconUrl} alt="Azura logo" className={className} />
    </>
  );
}
